import React from 'react';
import LeftSideBar from '../LeftSideBar';
import { useParams, useNavigate } from 'react-router-dom';
import { dynamicData } from '../../helpers/data';
import { useSelector } from 'react-redux';
import useFetchPdfReport from '../../api/useFetchPdfReport';
import useCreateUserAssessmentResponse from '../../api/CreateUserAccessment';
import useFetchData from '../../api/useFetchData';
import { notification, Spin } from 'antd';

export const ThankYouJc = () => {
    const isSchoolRegistration = useSelector( state => state.schoolRegistration.isSchoolRegistration )
    const navigate = useNavigate();
    const { client_name } = useParams();
    const formConfigRedux = useSelector( state => state.formConfiguration );

    const formConfig = formConfigRedux?.form_config;
    const clientid = formConfig?.client_id

    let thankYouPage =
        formConfig?.sign_up_content?.thank_you_page ||
        dynamicData[clientid]?.sign_up_content?.thank_you_page ||
        dynamicData.default?.sign_up_content?.thank_you_page;

    let heading = thankYouPage?.heading || "";
    let sub_heading_one = thankYouPage?.sub_heading_one || "";
    let sub_heading_two = thankYouPage?.sub_heading_two || "";
    let sub_heading_three = thankYouPage?.sub_heading_three || "";

    const handleTest = () => {
        window.open( `/${ client_name }/test/sign_in_page`, '_blank' );
    };

    return (
        <div className="p-8 bg-white shadow-lg rounded-lg max-w-xl mx-auto text-center">
            <h1 className="text-2xl font-bold mb-4">{ heading }</h1>
            <p className="mb-6">{ sub_heading_one }</p>
            <h1 className="text-2xl font-bold mb-4">{ sub_heading_two }</h1>
            <p className="mb-8">{ sub_heading_three }</p>
            <button className="bg-blue-500 text-white text-center rounded py-2 px-4 mb-2 hover:bg-blue-600" onClick={ handleTest }>
                Take me to the test
            </button>
        </div>
    );
};

export const ThankYouJcTest = () => {

    const { client_name } = useParams();
    const userAssessmentResponseID = localStorage.getItem( 'userAssessmentResponseID' )
    const student_name = localStorage.getItem( 'student_name' ) || "abc";
    const student_roll_number = localStorage.getItem( 'student_roll_number' )
    const { data: formData, isLoading: isFormDataLoading, isError: isFormDataError } = useFetchData( userAssessmentResponseID, client_name );
    const { fetchData, isLoading: isPdfDownloading } = useFetchPdfReport();
    const formConfigRedux = useSelector( state => state.formConfiguration );

    const formConfig = formConfigRedux?.form_config;
    const clientid = formConfig?.client_id

    let customData = {};

    if ( !isFormDataLoading ) {
        customData = {
            ...formData,
        };
    }

    const handleDownloadReport = async () => {
        const requestBody = {
            data: {
                template_name: "Template11",
                quiz_Type: "jc",
                student_Id: student_roll_number,
                user_name: student_name,
                report_id: userAssessmentResponseID,
                report_metadata: {
                    categories: customData
                }
            }
        };
        console.log( requestBody )
        try {
            const response = await fetchData( requestBody );
            console.log( "API Response:", response );
            if ( response && response.pdf ) {

                const downloadLink = document.createElement( "a" );
                downloadLink.style.display = "none";
                downloadLink.href = response.pdf;
                downloadLink.download = "icat-report.pdf"; // Specify the filename for the download

                // Append the anchor element to the document body
                document.body.appendChild( downloadLink );

                // Trigger a click event on the anchor to start the download
                downloadLink.click();

                // Remove the anchor from the document
                document.body.removeChild( downloadLink );
            } else {
                console.error( "API response does not contain a PDF link." );
                notification.error( {
                    message: 'PDF Download Failed',
                    description: 'Please try Again.',
                } );
            }
        } catch ( error ) {
            console.error( "API Error:", error );
        }
    };

    let thankYouPage =
    formConfig?.main_test_content?.thank_you_page ||
    dynamicData[clientid]?.main_test_content?.thank_you_page ||
    dynamicData.default?.main_test_content?.thank_you_page;

    let heading = thankYouPage?.heading || "";
    let sub_heading_one = thankYouPage?.sub_heading_one || "";
    let sub_heading_two = thankYouPage?.sub_heading_two || "";
    let sub_heading_three = thankYouPage?.sub_heading_three || "";

    return (
        <>
            {
                isFormDataLoading  ?
                    <Spin size='large' /> :
                    isPdfDownloading ?
                        <Spin size='large' /> :
                        <div className="p-8 bg-white shadow-lg rounded-lg max-w-xl mx-auto text-center">
                            <h1 className="text-2xl font-bold mb-4">{ heading }</h1>
                            <p className="text-xl text-black font-bold mb-4">{ sub_heading_one }</p>
                            <button className="bg-blue-500 text-white text-center rounded py-2 px-4 mb-2 hover:bg-blue-600" onClick={ handleDownloadReport }>
                                Download your PDF report
                            </button>
                            <h1 className="text-xl text-black font-bold mb-4 mt-5">{ sub_heading_two }</h1>
                            <a href="https://booking.strideahead.in/" className="bg-blue-500 text-white text-center rounded py-2 px-4 mb-2 hover:bg-blue-600">
                                Book the Mentorship Session now!
                            </a>
                        </div>
            }
        </>
    );
};

